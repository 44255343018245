.card_container .row {
  padding: 50px;
  padding-left: 0px;
  padding-right: 0px;
  height: fit-content;
  display: flex;
  justify-content: center;
}

.card_container .row img {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 0px;
  height: 430px;
  width: 430px;
}

/* responsive code */
@media (max-width:2500px) {
  .card_container .row img {
    height: 490px;
    width: 500px;
  }
}

@media (max-width:1200px) {
  .card_container .row img {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 400px;
    width: 350px;
  }
}

@media (max-width:1100px) {
  .card_container .row {
    padding: 20px;
    padding-top: 60px;
    align-items: center;
  }

  .card_container .row img {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 400px;
    width: 350px;
  }
}

@media (max-width:1000px) {
  .card_container .row img {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 300px;
    width: 250px;
  }
}

@media (max-width:800px) {
  .card_container .row img {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 200px;
    width: 200px;
  }
}

@media (max-width:540px) {
  .card_container .row img {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 200px;
    width: 200px;
  }
}
@media (max-width:500px) {
  .card_container .row img {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 200px;
    width: 160px;
  }
}
@media (max-width:434px) {
  .card_container .row img {
    margin-top: 0px;
    margin-bottom: 10px;
    height: 100%;
    width: 100%;
  }
}