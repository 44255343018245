.services_rows {
 padding: 50px;
 margin-top: 150px;
 margin-bottom: 20px;
 display: flex;
 flex-direction: row;
 justify-content: center;
 border: none;
}

.services_rows .content {
 margin-left: -230px;
 margin-right: 40px;
 width: 700px;
}

.services_rows .content a {
 margin-bottom: 0px;
 border-radius: 50px !important;
 border:1px solid white !important;
}

.services_rows img {
 height: 350px;
 width: 460px;
}

.services_rows .content a img {
 margin-left: 10px;
 height: 22px;
 width: 22px;
 border-radius: 0;
 box-shadow: none;
}

.card_rows {
 padding: 20px;
 padding-right: 40px;
 margin-bottom: 20px;
 display: grid;
 grid-template-columns: 1fr 1fr 1fr 1fr;
 grid-gap: 15px;
 border: none;
}

.card_rows .card {
 margin-right: 5px;
 width: fit-content;
 box-shadow: 0px 2px 5px 0px rgba(219, 217, 217, 0.75);
 -webkit-box-shadow: 0px 2px 5px 0px rgba(219, 217, 217, 0.75);
 -moz-box-shadow: 0px 2px 5px 0px rgba(219, 217, 217, 0.75);
 backdrop-filter: blur(1px);
 border-radius: 10px !important;
}

.card_rows .card img {
 height: 180px;
 width: 220px;
 border-radius: 10px !important;
}

/* responsive */
@media (max-width:1100px) {
 .services_rows {
  padding: 190px;
  padding-top: 0;
  padding-bottom: 20px;
  margin-top: 0;
  flex-direction: column;
 }

 .services_rows .content {
  margin-left: 0px;
  width: 100%;
 }

 .services_rows .content p {
  text-align: justify;
 }

 .services_rows .content a {
  margin-bottom: 50px;
 }

 .services_rows img {
  height: 100%;
  width: 100%;
 }

 .card_rows {
  margin-left: 0;
  padding: 0;
  grid-gap: 20px;
  /* grid-template-columns: 1fr 1fr; */
 }

 .card_rows .card {
  margin-left: -10px;
  margin-bottom: 15px;
 }

 .card_rows .card .card-title {
  font-size: 12px;
 }

 .card_rows .card img {
  height: 90px;
  width: 140px;
 }
}

@media (max-width:1010px) {
 .card_rows {
  grid-template-columns: 2fr 2fr;
 }

 .card_rows .card img {
  height: 150px;
  width: 250px;
 }
}

@media (max-width:920px) {
 .card_rows {
  grid-template-columns: 2fr 2fr;
 }

 .card_rows .card img {
  height: 100px;
  width: 200px;
 }
}


@media (max-width:800px) {
 .services_rows {
  padding: 10px;
  padding-top: 0;
  padding-bottom: 20px;
  margin-top: 0;
  flex-direction: column;
 }

 .card_rows {
  margin-left: 0;
  padding: 0;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
 }

 .card_rows .card img {
  height: 100px;
  width: 150px;
 }

}

@media (max-width:350px) {
 .card_rows {
  margin-left: 0;
  padding: 0;
  grid-gap: 20px;
  grid-template-columns: 1fr;
 }

 .card_rows .card img {
  height: 100%;
  width: 100%;
 }

}