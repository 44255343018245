#payment-form {
    height: 95vh;
    display: grid;
    place-items: center;
}

#payment-form #main_title1 {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 38px;
    background-color: #076BFF !important;
    border-radius: 10px;
    color: white;
    letter-spacing: 2px;
}

#payment-form #main_title2 {
    font-size: 45px;
    letter-spacing: 2px;
}

.full_name_input {
    background-color: rgb(241, 241, 241);
    border-radius: 8px;
    border: 1px solid #838383 !important;
}


.payment_row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

label[data-shrink=false]+.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    background: white !important;
    color: black !important;
}

.payment_content {
    margin-left: 20px;
    padding: 50px;
    padding-top: 0px;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.payment_content_title {
    font-size: 40px;
}

.full_name_input {
    background-color: rgb(241, 241, 241);
    border-radius: 8px;
}

.payment_content #email {
    margin-left: -10px;
    margin-top: -10px;
    border: none !important;
    background: none;
}

.dropdown-menu.show {
    width: 100% !important;
}

.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    border: none !important;
}

#card-element {
    padding: 10px;
    width: 300px;
    border: 1px solid #8c8c8c !important;
    border-radius: 10px;
}

#submit {
    color: white !important;
    border-radius: 10px !important;
    background-color: #076BFF !important;
}

#submit #button-text {
    color: white !important;
    font-weight: bold;
}


/* payment details */
.PaymentDetails {
    width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 0.5px solid #eaeaea !important;
    box-shadow: 1px 0px 5px 2px rgb(234, 234, 234);
    -webkit-box-shadow: 1px 0px 5px 2px rgb(234, 234, 234);
    -moz-box-shadow: 1px 0px 5px 2px rgb(234, 234, 234);
}

.PaymentDetails #price {
    font-size: 70px;
}

.PaymentDetails #month_span {
    font-size: 16px;
    letter-spacing: 2px;
}

.PaymentDetails .divider {
    height: 2px;
    width: 85%;
    background-color: rgb(167, 167, 167);
}

.PaymentDetails .feature_list #li {
    display: flex;
}

.PaymentDetails .feature_list #li #icon {
    padding: 3px;
    height: 0.8em;
    width: 0.8em;
    border-radius: 50px !important;
    color: white !important;
    background-color: rgb(238, 156, 60);
}

.PaymentDetails .css-i4bv87-MuiSvgIcon-root {
    fill: white !important;
}



.footer_span {
    font-size: 14px;
}

/*responsive*/
@media (max-width: 900px) {
    #payment-form {
        padding-top: 20px;
        padding-bottom: 20px;
        height: fit-content;
    }

    .payment_content {
        padding: 30px;
        margin-top: 20px;
    }
}

@media (max-width: 730px) {
    .payment_row {
        flex-direction: column;
    }

    .payment_content {
        margin-left: 0;
    }

    .PaymentDetails {
        margin-left: 0;
    }
}

@media (max-width: 390px) {
    #payment-form #main_title1 {
        font-size: 25px;
    }

    #payment-form #main_title2 {
        font-size: 30px;
    }

    .payment_row {
        flex-direction: column;
    }

    .payment_content {
        width: 100%;
        margin-left: 0;
        box-shadow: none;
    }

    .PaymentDetails {
        margin-left: 0;
        padding-left: 20px;
        width: 100%;
        overflow: hidden;
    }

    #card-element {
        width: 100% !important;
    }

    .css-viou3o-MuiAutocomplete-root {
        width: 100% !important;
        background-color: white !important;
        color: white !important;
    }
}