/* main container */
.HousePrediction {
 height: 70vh;
 display: flex;
 justify-content: space-around;
 align-items: center;
}

/* features_input */
.HousePrediction .features_input {
 width: 350px;
 border-radius: 5px;
 background-color: black !important;
 border: 1px solid rgb(71, 71, 71) !important;
}

.HousePrediction .features_input .room_field {
 padding: 15px !important;
 padding-top: 5px !important;
 padding-bottom: 5px !important;
}

.HousePrediction .features_input input {
 background-color: black;
 color: white;
 border: 1px solid rgb(71, 71, 71) !important;
}

.HousePrediction .features_input #predict_btn {
 background-color: #200EF7 !important;
 color: white;
 border-radius: 5px !important;
}

/* result box */
.HousePrediction .output_console_card {
 padding-bottom: 20px;
 height: fit-content;
 width: 400px;
 background-color: #0B0B0B;
 border: 1px solid rgb(106, 106, 106) !important;
 border-radius: 10px;
}

.HousePrediction .output_console_card h6 {
 padding: 10px;
 width: 100%;
 background-color: #7200E3 !important;
 border-radius: 0px !important;
}

.HousePrediction.result_box span {
 display: block;
}

.HousePrediction .result_box span {
 margin-left: 10px;
 padding: 3px;
}


/* responsive */
@media (max-width:1100px) {
 .HousePrediction {
  flex-direction: column;
  justify-content: center;
  flex-flow: column-reverse;
 }

 .HousePrediction .connector {
  display: none;
 }

 .HousePrediction .output_console_card {
  margin-bottom: 40px;
 }
}

@media (max-width:450px) {
 .HousePrediction .features_input {
  width: 100%;
 }

 .HousePrediction .connector {
  display: none;
 }

 .HousePrediction .output_console_card {
  width: 100%;
  overflow: visible;
 }
}