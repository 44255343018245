@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@700&family=Roboto+Mono:wght@500&display=swap');

.intro {
 height: fit-content;
 padding-bottom: 0px;
}

.intro .comp1 h1 {
 top: 70px;
 margin-left: 100px;
 font-size: 150px;
 letter-spacing: 20px;
 position: absolute;
 font-family: 'Almarai', sans-serif;
}

.intro .comp1 h1 #u {
 left: -30px;
 top: 35px;
 color: red !important;
 position: relative;
 background-color: unset !important;
}

.intro .comp1 h1 #res {
 left: -125px;
 top: -50px;
 position: relative;
 background-color: unset !important;
}

.intro .comp1 img {
 height: 500px;
 width: 50%;
 border-radius: 5px;
 position: relative;
 z-index: -1;
}

/* stylus support */
.intro .comp2 {
 margin-top: -200px;
 margin-left: 40%;
 padding: 60px;
 padding-top: 40px;
 padding-bottom: 30px;
 height: fit-content;
 width: fit-content;
 border-radius: 5px;
 background-color: white;
 box-shadow: 0px 2px 5px 0px rgba(153, 153, 153, 0.75);
 -webkit-box-shadow: 0px 2px 5px 0px rgba(153, 153, 153, 0.75);
 -moz-box-shadow: 0px 2px 5px 0px rgba(153, 153, 153, 0.75);
 position: relative;
 z-index: 3;
}

.intro .comp2 .comp2_header {
 display: flex;
}

.intro .comp2 .comp2_header .comp2_header_title {
 margin-left: 20px;
 letter-spacing: 1px;
 font-family: 'Almarai', sans-serif;
}

.intro .comp2 .comp2_header #pen1 {
 margin-right: -100px;
 margin-top: 10px;
 right:0px;
 height: 25px;
 width: 40%;
 position: absolute;
 border-radius: 100px;
}

.intro .comp2 .comp2_footer {
 display: flex;
}

.intro .comp2 .comp2_footer #pen2 {
 margin-left: 10px;
 margin-right: 60px;
 height: 200px;
}

.intro .comp2 .comp2_footer span {
 padding-right: 20px;
 text-align: justify;
 font-size: 24px;
}

.intro #intro_liner {
 margin-top: -180px;
 margin-left:50px;
 height:300px;
 position: sticky;
}


/* responsiveness */
@media (max-width:1150px){
 .intro .comp1 h1 {
  margin-left: 10px;
 }
 .intro #intro_liner {
  height:300px;
 }
}
@media (max-width:992px) {
 .intro .comp1 h1 {
  font-size: 100px;
 }

 .intro .comp1 h1 #u {
  left: -20px;
  top: 30px;
 }

 .intro .comp1 h1 #res {
  left: -90px;
  top: -40px;
  position: relative;
 }

 .intro .comp2 {
  /* margin-top: -300px; */
  padding: 20px;
 }

 .intro .comp1 img {
  height: 400px;
 }

 .intro .comp2 .comp2_header .comp2_header_title {
  font-size: 30px;
 }

 .intro .comp2 .comp2_header #pen1 {
  display: none;
 }

 .intro .comp2 .comp2_footer #pen2 {
  height: 120px;
  margin-right: 20px;
 }

 .intro .comp2 .comp2_footer h3 {
  font-size: 20px;
 }
 .intro .comp2 .comp2_footer span {
  font-size: 18px;
 }
}

@media (max-width:770px) {
 .intro .comp1 h1 {
  font-size: 70px;
 }

 .intro .comp1 h1 #u {
  left: -10px;
  top: 14px;
 }

 .intro .comp1 img {
  margin-top: 60px;
  height: 350px;
  width: 100%;
 }

 .intro .comp1 h1 #res {
  left: -65px;
  top: -20px;
 }

 .intro .comp2 {
  margin-left: 0px;
  padding: 20px;
 }

 .intro .comp2 .comp2_header .comp2_header_title {
  font-size: 30px;
 }

 .intro .comp2 .comp2_header #pen1 {
  display: none;
 }

 .intro .comp2 .comp2_footer #pen2 {
  height: 120px;
  margin-right: 20px;
 }

 .intro .comp2 .comp2_footer h3 {
  font-size: 20px;
 }
}


@media (max-width:650px) {
 .intro .comp1 h1 {
  letter-spacing: 5px;
 }

 .intro .comp1 h1 #res {
  left: -50px;
 }
}

@media (max-width:530px) {
 .intro .comp1 img {
  margin-top: 50px;
 }
 .intro .comp1 h1 {
  left: 5%;
 }
}

@media (max-width:430px) {
 .intro .comp1 h1 {
  font-size: 60px;
 }

 .intro .comp1 h1 #res {
  left: -41px;
 }

 .intro .comp1 h1 #res {
  left: -45px;
 }
}

@media (max-width:390px) {
 .intro .comp2 {
  padding: 10px;
 }

 .intro .comp2 .comp2_footer #pen2 {
  height: 190px;
 }

 .intro .comp1 img {
  margin-top: 30px;
  height: 280px;
  width: 100%;
 }

 .intro .comp1 h1 {
  top: 20px;
 }
}

@media (max-width:365px) {
 .intro .comp1 h1 {
  font-size: 50px;
 }

 .intro .comp1 h1 #res {
  top: -15px;
  left: -35px;
 }

 .intro .comp1 h1 #res {
  left: -40px;
 }
}

@media (max-width:330px) {
 .intro .comp1 h1 {
  margin-top: 10px;
  font-size: 40px;
  letter-spacing: 0;
 }

 .intro .comp2 .comp2_header .comp2_header_title {
  margin-left: 10px;
  letter-spacing: 0px;
  font-size: 20px;
  font-family: 'Almarai', sans-serif;
 }

 .intro .comp1 h1 #u {
  left: -5px;
 }

 .intro .comp1 h1 #res {
  top: -15px;
  left: -25px;
 }

 .intro .comp2 .comp2_footer #pen2 {
  margin: 0;
 }

 .intro .comp2 .comp2_footer h3 {
  font-size: 18px;
 }
}
