* {
  margin: 0;
  padding: 0;
}

.homepage {
  padding-top: 15px;
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  object-fit: fill;
}

.homepage .video {
  height: 90vh !important;
  width: 100% !important;
  object-fit: fill;
  border-radius: 20px/20px;
}


/*  */
@media (max-width:1100px) {
  .homepage {
    height: fit-content;
  }

  .homepage .videoContainer {
    margin-top: 0;
  }

  .homepage .video {
    height: 80vh !important;
  }
}

/* ipad mini */
@media (max-width:820px) and (min-height:1180px) {
  .homepage .video {
    height: 40vh !important;
  }
}

/* mobile view */
@media (max-width:600px) {
  .homepage .videoContainer {
    margin-top: 0;
  }

  .homepage .video {
    height: 50vh !important;
  }
}

@media (max-width:600px) {
  .homepage .video {
    height: 40vh !important;
  }
}