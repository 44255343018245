.journey {
 height: fit-content;
}

.journey_row {
 padding-top: 10px;
 height: 100%;
 display: flex;
}

.journey .leftBar {
 margin-top: 80px;
 width: 35%;
 height: 200px;
 display: flex;
 flex-display: column;
 align-items:end;
 background-color: red !important;
}
.journey .leftBar .leftBar_btm{
   height:30px;
   width:100%;
   background: #2c2c2c;
}

.journey .content {
 height: fit-content;
 width: fit-content;
}

.journey .content h1 {
 font-size: 300px;
 font-weight: bold;
 color: red;
}

.journey .content #on {
 margin-left: 200px !important;
 color: #383838 !important;
 background-color: unset !important;
}
.journey .content #step {
 margin-top: -100px;
 margin-left: -300px !important;
 background-color: unset !important;
 color: red !important;
}

.journey .rightBar {
 top: 80px;
 margin-left: auto;
 padding-left: 200px;
 width: 70%;
 height: fit-content;
 text-align: start;
 position: relative;
}

.journey .rightBar h1 {
 font-size: 150px;
 font-weight: bold;
 background-color: unset !important;
}

.journey .rightBar #journey {
 margin-top: -50px;
 margin-left: 190px;
}

.journey #journey_step_img {
 margin-top: -1370px;
 height: 100%;
 background-color: unset !important;
}

@media(max-width:1800px){
 .journey .rightBar h1 {
  margin-left: -200px;
 }
 .journey .rightBar #journey {
  margin-left: -10px;
 }
}
@media (max-width:1100px) {
 .journey .journey_row {
  flex-direction: column;
 }
 .journey .rightBar h1 {
  margin-left: -100px;
 }
 .journey .rightBar #journey {
  margin-left: 100px;
 }
 .journey .leftBar {
  margin-top: 0px;
  width: 40%;
  height: 100px;
 }
 .journey .rightBar {
  padding-left: 100px;
  width: 90%;
 }

 .journey .journey_row {
  justify-content: center;
 }

 .journey .journey_row .content {
  flex-direction: row !important;
 }

 .journey .content h1 {
  font-size: 150px;
 }

 .journey .content #on {
  margin-left: 0px !important;
 }

 .journey .content #step {
  margin-top: 0px;
  margin-left: 50px !important;
 }

 .journey #journey_step_img {
  display: none;
 }
}

@media (max-width:920px) {
 .cardRow {
  padding-top: 0px !important;
 }
 .journey .rightBar {
  top: 20px;
 }
 .journey .rightBar #journey {
  margin-left: 0px;
 }
 .journey .leftBar {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  border-top-left-radius: unset !important;
  overflow:hidden;
 }
}
@media (max-width:830px) {
 .journey .rightBar {
  padding-left: 100px;
 }
 .journey .journey_row .content {
  margin-left: 50px;
 }
}

@media (max-width:775px) {
 .journey .content #step {
  margin-top: 75px;
  margin-left: 0px !important;
 }
 .journey .rightBar {
  width: 95%;
 }
 .journey .rightBar #journey {
  margin-left: -50px;
 }
}

@media (max-width:700px) {
 .journey .rightBar {
  padding-left: 60px;
  width: 70%;
 }

 .journey .rightBar h1 {
  margin-left: -200px;
  font-size: 100px;
 }

 .journey .rightBar #journey {
  margin-top: -20px;
  margin-left: -50px;
 }
 .journey .content h1 {
  font-size: 100px;
 }

}

@media (max-width:574px) {
 .journey .leftBar {
  height:80px;
  width: 50%;
 }

 .journey .rightBar {
  width: 90%;
 }

 .journey .rightBar h1 {
  margin-left: -80px;
  font-size: 70px;
 }
 .journey .rightBar #journey {
  margin-left: 50px;
 }
}

@media (max-width:500px) {
 .journey .content #step {
  margin-left: 20px !important;
 }
 .journey .journey_row .content {
  margin-left: 20px;
 }

 .journey .content h1 {
  font-size: 80px;
 }
 .journey .rightBar h1 {
  margin-left: 0px;
  font-size: 70px;
 }
 .journey .rightBar #journey {
  margin-left: 100px;
 }
 .journey .rightBar {
  width: 98%;
  padding-left: 30px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
 }

}

@media (max-width:450px) {
 .journey .leftBar {
  width: 70%;
 }
 .journey .rightBar h1 {
  margin-left: 0px;
  font-size: 60px;
 }
}

@media (max-width:400px) {
 .journey .rightBar h1 {
  font-size: 50px;
 }
}
@media (max-width:370px) {
 .journey .content h1 {
  font-size: 70px;
 }
 .journey .content #step {
  margin-left: -10px !important;
 }
 .journey .rightBar h1 {
  margin-left: -20px;
 }
 .journey .rightBar #journey {
  margin-left: 30px;
 }
}
@media (max-width:310px) {
 .journey .journey_row .content {
  margin-left: 30px;
 }
 .journey .content h1 {
  font-size: 50px;
 }
}