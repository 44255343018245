.studentPlan {
 padding: 0;
 margin: 0;
}

.row_container {
 height: 100vh;
 display: flex;
 justify-content: center;
}

/* row1 */
.row_container .row1 {
 width: 50%;
 display: flex;
 flex-direction: column;
 justify-content: center;
}

.row_container .row1 p {
 width: 350px;
}

/* row2 */
.row_container .row2 {
 padding-top: 20px;
 padding-bottom: 20px;
 width: 50%;
 background-color: #00ED37;
}

.row_container .row2 #row2_title {
 left: -155px;
 font-size: 120px;
 letter-spacing: 1px;
 position: relative;
 font-weight: bold;
}

.row_container .row2 #row2_sub_title {
 margin-left: 30px;
 font-size: 120px;
 letter-spacing: 1px;
 font-weight: bold;
}

.row_container .row2 #row2_years {
 margin-left: 30px;
 font-size: 120px;
 letter-spacing: 1px;
 font-weight: bold;
}

.row_container .row2 #row2_years #num {
 margin-left: -10px;
 font-size: 80px;
}

.row_container .row2 #row2_years #years {
 font-size: 50px;
}

.apply_btn {
 margin-top: 100px;
 margin-left: -80px;
 border: 2px solid black;
 border-radius: 5px !important;
}


/* responsive */
@media (max-width:1100px) {
 .row_container {
  height: fit-content;
  flex-direction: column;
 }

 .row_container .row1 {
  width: 100%;
 }

 .row_container .row2 {
  width: 100%;
  overflow: hidden;
 }

 .row_container .row2 #row2_title {
  font-size: 100px;
  left: 30px;
 }

 .row_container .row2 #row2_sub_title {
  margin-left: 30px;
  font-size: 100px;
 }

 .apply_btn {
  margin-top: 0px;
  margin-left: 50px;
 }
}

@media (max-width:450px) {
 .row_container .row2 #row2_title {
  font-size: 90px;
  left: 20px;
 }

 .row_container .row2 #row2_sub_title {
  margin-left: 20px;
  font-size: 90px;
 }
}

@media (max-width:400px) {
 .row_container .row1 p {
  width: 100%;
 }

 .row_container .row2 #row2_title {
  font-size: 70px;
  left: 20px;
 }

 .row_container .row2 #row2_sub_title {
  margin-left: 20px;
  font-size: 70px;
 }
}

@media (max-width:340px) {
 .row_container .row2 #row2_title {
  font-size: 60px;
  left: 20px;
 }

 .row_container .row2 #row2_sub_title {
  margin-left: 20px;
  font-size: 60px;
 }

 .row_container .row2 #row2_years {
   margin-top: -10px;
  margin-left: 20px;
  font-size: 120px;
 }
}