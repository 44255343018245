.cards_container {
 height: fit-content;
 display: flex;
 justify-content: space-between;
}

/* card1 */
.cards_container .card1 {
 padding: 50px;
 padding-left: 60px;
 padding-right: 60px;
 width: 49%;
 display: flex;
 flex-direction: column;
 align-items: center;
 border: none;
 background-color: #EEF4F6;
 box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
 -webkit-box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
 -moz-box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
}

.cards_container .card1 img {
 height: 200px;
 width: 200px;
 border-radius: 5px;
}

.cards_container .card1 button {
 width: fit-content;
 color: white;
 border-radius: 20px;
 border: 0px;
 border-radius: 5px !important;
 background-color: red !important;
}

.cards_container .card1 button:hover{
  border-color: red !important;
}

 /* card 2 */
 .cards_container .card2 {
  padding: 50px;
  padding-top: 80px;
  padding-left: 60px;
  padding-right: 60px;
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: none;
  background-color: #EEF4F6;
  box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
  -webkit-box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
  -moz-box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
 }

 .cards_container .card2 img {
  height: 200px;
  width: 250px;
 }

 /* responsive */
 @media(max-width:1000px) {
  .cards_container .card1, .cards_container .card2 {
   padding: 20px;
  }

  .cards_container .card1 img, .cards_container .card2 img {
   height: 150px;
   width: 200px;
  }
 }

 @media(max-width:770px) {
  .cards_container {
   padding: 0px;
  }

  .cards_container .card1 button {
   padding-top: 5px !important;
   padding-bottom: 5px !important;
   padding-left: 25px !important;
   padding-right: 25px !important;
   border-radius: 5px;
  }
 }

 @media(max-width:560px) {
  .cards_container .card2 img {
   margin-top: 10px;
  }

  .cards_container .card1 img, .cards_container .card2 img {
   height: 100px;
   width: 150px;
  }

  .cards_container .card1 h5, .cards_container .card2 h5 {
   font-size: 14px;
  }

  .cards_container .card2 h5 {
   margin-top: 20px !important;
   font-size: 14px;
  }
 }

 @media(max-width:450px) {
  .cards_container {
   flex-direction: column-reverse;
   align-items: center;
  }

  .cards_container .card1 img, .cards_container .card2 img {
   height: 150px;
   width: 150px;
  }

  .cards_container .card1, .cards_container .card2 {
   padding-top: 30px;
   padding-bottom: 50px;
   margin-top: 20px;
   width: 100%;
  }

  .cards_container .card1 button {
   padding-top: 5px !important;
   padding-bottom: 5px !important;
   padding-left: 25px !important;
   padding-right: 25px !important;
   border-radius: 5px;
  }

  .cards_container .card1 img, .cards_container .card2 img {
   height: 120px;
   width: 120px;
  }

  .cards_container .card2 h5, .cards_container .card1 h5 {
   margin-top: 20px !important;
   margin-bottom: 20px !important;
   text-align: center;
  }

 }