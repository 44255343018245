.languages {
  padding-top: 50px;
  padding-bottom: 50px;
}

.languages center{
  background:unset !important;
}
.languages #title {
  font-weight: bold;
  background:unset !important;
}
.languages .icon_container{
  background: unset !important;
}

.languages .icon_container {
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 20px;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
}

.languages .icon_container #icon {
  height: 100px;
  width: 100px;
  background: unset !important;
}

@media (max-width:1100px) {
  .languages .icon_container {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0px;
    height: 50px;
    flex-wrap: wrap;
  }

  .languages .icon_container #icon {
    height: 50px;
    width: 50px;
  }
}

@media (max-width:400px) {
  .languages #title {
    font-size: 30px;
  }
}