.downloads .downloads_box {
  width: 90%;
  display: flex;
  margin: 50px auto;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
  -webkit-box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
  -moz-box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
}

.downloads_box_card {
  margin-top: 30px;
  margin-right: 30px;
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 370px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  /* box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
  -webkit-box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
  -moz-box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
} */
}
.beta_container {
  margin-top: -22px;
  margin-left: 45px;
  margin-bottom: 10px;
}

.downloads_box_card div #beta_text {
  margin-left: -20px;
  padding: 2px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  color: white;
  border-radius: 5px;
}

.downloads_box_card #MAC {
  background-color: #1c73f2 !important;
  border-radius: 5px !important;
}

.downloads_box_card #WINDOWS {
  background-color: #00adef !important;
  border-radius: 5px !important;
}
.downloads_box_card #LINUX {
  background-color: #0288bd !important;
  border-radius: 5px !important;
}

.downloads_box_card img {
  height: 180px;
  width: 180px;
}

.downloads_box_card_content {
  padding: 10px;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  background-color: #ececec;
  border-radius: 10px;
}

.downloads_box_card_content span {
  margin-top: 10px;
}

/*responsive*/
@media (max-width: 550px) {
  .downloads .downloads_box {
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  .downloads_box_card {
    margin-right: 0px;
    margin-left: 0px;
    width: 100%;
    box-shadow: none !important;
  }

  .downloads_box_card_content span {
    text-align: center;
  }
}
