.guide {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.guide .guide_rows {
  padding-top: 30px;
  padding-bottom: 45px;
  margin-left: -25px;
  width: 954px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px !important;
  box-shadow: 0px 2px 5px 0px rgba(216, 216, 216, 0.75);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(216, 216, 216, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(216, 216, 216, 0.75);
}

.guide .guide_rows .title {
  margin-bottom: 40px;
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: unset !important;
}

.guide .guide_rows .title h1 {
  position: absolute;
}

.guide .guide_rows .title .input_icon {
  height: 50px;
  width: 350px;
}

.guide .guide_rows .title .output_icon {
  height: 60px;
  width: 400px;
}

.guide .guide_rows p {
  width: 600px;
  font-size: 18px;
  text-align: center;
}

.guide .guide_rows div .img_container {
  padding-right: 50px;
  height: 150px;
  width: fit-content;
  padding-left: 20px;
}

.guide .guide_rows div .img_container img {
  height: 100%;
  width: 100%;
}

.guide .guide_rows div .img_container .icon2 {
  margin-top: 5px;
  margin-left: 10px;
  height: 140px;
}

.guide .guide_rows .btn {
  width: 250px;
  color: white;
  font-weight: bold;
  background-color: #9159a3 !important;
  border: 1px solid white;
  border-radius: 50px !important;
}

@media (max-width: 1100px) {
  .guide .guide_rows {
    padding: 115px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 0px;
    width: 100%;
    overflow: hidden;
    box-shadow: none;
  }

  .guide .guide_rows img {
    margin-right: 10px;
  }

  .guide .guide_rows .title h1 {
    position: static;
  }

  .guide .guide_rows .title .input_icon {
    height: 40px;
    width: 150px;
  }

  .guide .guide_rows .title .output_icon {
    height: 50px;
    width: 100px;
  }

  .guide .guide_rows div .img_container {
    height: 100%;
  }

  .guide .guide_rows div .img_container .icon2 {
    margin-top: 0px;
    height: 100%;
  }

  .guide .guide_rows p {
    text-align: justify;
    width: fit-content;
  }
}

@media (max-width: 990px) {
  .guide .guide_rows {
    padding: 35px;
  }
}

@media (max-width: 800px) {
  .guide {
    padding-right: 10px;
    max-width: 100% !important;
  }

  .guide .guide_rows {
    padding: 20px;
  }

  .guide .guide_rows .title {
    justify-content: center;
    margin-bottom: 20px;
  }

  .guide .guide_rows .title .input_icon {
    display: none;
  }

  .guide .guide_rows .title .output_icon {
    height: 60px;
    width: 120px;
  }
}

/* ipad mini */
@media (max-width: 820px) and (min-height: 1180px) {
  .guide .guide_rows {
    padding: 90px;
    padding-top: 50px;
    padding-bottom: 0;
  }
}

@media (max-width: 390px) {
  .guide {
    padding: 0px;
  }

  .guide .guide_rows .title .output_icon {
    height: 50px;
    width: 90px;
  }

  .guide .guide_rows .title .output_icon {
    height: 50px;
    width: 90px;
  }

  .guide .guide_rows p {
    width: inherit;
  }
}
