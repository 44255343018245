@import url('https://fonts.googleapis.com/css2?family=Karla&family=PT+Sans&display=swap');

/* custom css */
body {
    background-color: #8f8f8f;
}


p {
    font-family: 'PT Sans', sans-serif;
}

h1, span, h3 {
    color: rgb(46, 31, 31);
}

.btn {
    border-radius: 50px !important;
}

.Toastify__toast {
    border-radius: 10px !important;
    border: 1px solid white;
}

.navbar li a span {
    background: unset !important;
}

@media (max-width: 1200px) {
    *>h1 {
        font-size: 18px;
    }

    *>h3 {
        font-size: 16px;
    }

    *>p {
        font-size: 15px;
    }
}