.install_discover {
    top: 0;
    left: 0;
    padding-top: 50px;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 4;
    backdrop-filter: blur(5px);
    background: rgba(107, 106, 106, 0.5);
    overflow-y: scroll;
}

.install_discover .install_discover_card {
    padding: 10px;
    padding-top: 20px;
    height: fit-content;
    width: 90%;
    border-radius: 10px !important;
    overflow: hidden;
    background-color: white;
    box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
    -webkit-box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
    -moz-box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
}

.discover_card_content {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.discover_card_content .videoContainer {
    height: 300px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px !important;
    box-shadow: 1px 0px 5px 2px rgb(229, 229, 229);
    -webkit-box-shadow: 1px 0px 5px 2px rgb(229, 229, 229);
    -moz-box-shadow: 1px 0px 5px 2px rgb(232, 232, 232);
}
.discover_card_content input{
    margin-bottom: 8px;
}

.discover_card_content .videoContainer #video_icon {
    height: 3em;
    width: 3em;
    color: #808080;
    cursor: pointer;
}
.discover_card_content .videoContainer #video_icon:hover{
    opacity:0.8;
}

.discover_card_content .photoContainer {
    height: 200px;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px !important;
    box-shadow: 1px 0px 5px 2px rgb(229, 229, 229);
    -webkit-box-shadow: 1px 0px 5px 2px rgb(229, 229, 229);
    -moz-box-shadow: 1px 0px 5px 2px rgb(232, 232, 232);
    overflow: hidden;
}
.discover_card_content .photoContainer #photo_icon {
    height: 70%;
    width: 70%;
    color: #808080;
    cursor: pointer;
object-fit: contain;
}
.discover_card_content .photoContainer #photo_icon:hover{
    opacity:0.8;
}

.discover_card_content img {
    margin-left: 20px;
    margin-right: 20px;
    width: 250px;
    height: 40px;
}

.input_div {
    padding: 20px;
    height: fit-content;
    width: 400px;
    border-radius: 10px !important;
    border: 0.5px solid #e5e1e1;
    overflow: hidden;
    box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
    -webkit-box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);
    -moz-box-shadow: 1px 0px 5px 2px rgba(181, 181, 181, 0.47);

}

.input_div_item {
    width: 90%;
    display: flex;
    background: #eaeaea;
    border-radius: 5px !important;
    border: 1px solid #464646;
}

#result_title {
    width: 100%;
}

/*bottom navbar*/
.installBottomNav {
    display: flex;
    justify-content: center;
}

.installBottomNav div {
    width: fit-content;
    color: white;
}

.save {
    background: #4A3BFF !important;
}

.mic {
    background: #7200E3 !important;
}

.installBottomNav {
    margin-top: 80px;
}

.installBottomNav div .icons {
    background: unset !important;
}

/*responsive*/
@media (max-width: 1100px) {
    .install_discover .install_discover_card {
        width: 100%;
        height: fit-content;
        overflow-y: scroll;
    }

    .install_navbar {
        padding-left: 0px;
        padding-right: 0px;
    }

    .install_navbar_img {
        height: 50px;
        width: 60px;
    }

    .install_navbar_img img {
        height: 20px;
        width: 40px;
    }

    .discover_card_content {
        flex-direction: column;
    }

    .installBottomNav {
        margin-top: 0px;
    }

    .input_div {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

@media (max-width: 300px) {
    .input_div {
        box-shadow: none;
        padding: 30px;
    }
}