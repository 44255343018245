.beta {
 padding-top: 0px;
 padding-bottom: 60px;
 height: 100vh;
 overflow: hidden;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 border: 0px white;
 background: rgb(255,100,100);
 background: -moz-linear-gradient(90deg, rgba(255,100,100,1) 0%, rgba(169,0,250,1) 100%);
 background: -webkit-linear-gradient(90deg, rgba(255,100,100,1) 0%, rgba(169,0,250,1) 100%);
 background: linear-gradient(90deg, rgba(255,100,100,1) 0%, rgba(169,0,250,1) 100%);
 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff6464",endColorstr="#a900fa",GradientType=1);
}

.beta #title {
 font-size:70px;
 color: white;
 background-color: unset !important;
}

.beta #sub_title {
 font-size:30px;
 color: white;
 font-weight:lighter;
 background-color: unset !important;
}

.beta .counter_div {
 padding: 40px;
 width:1000px;
 height:300px;
 display: flex;
 justify-content: center;
 align-items: center;
backdrop-filter: blur(5px) !important;
 background-color: rgb(44 36 36 / 50%) !important;
 border-radius: 10px !important;
}

.beta .counter_div .count1, .beta .counter_div .count2, .beta .counter_div .count3 {
 padding: 100px;
 margin-left: 20px;
 margin-right: 20px;
 height: 150px;
 width: 150px;
 font-size: 130px;
 font-weight: bolder;
 color: white;
 display: flex;
 justify-content: center;
 align-items: center;
 background:unset !important;
}
.beta .counter_div div span{
 color: #ddd !important;
 background:unset !important;
}

/* responsive */
@media (max-width:650px) {
 .beta .counter_div .count1, .beta .counter_div .count2, .beta .counter_div .count3 {
  height: fit-content;
  width:fit-content;
  padding:10px !important;
  font-size: 80px;
 }
 .beta .counter_div div span{
  font-size: 20px;
 }
}

@media (max-width:500px) {
 .beta #title {
  font-size: 50px;
 }

 .beta .counter_div .count1, .beta .counter_div .count2, .beta .counter_div .count3 {
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 80px;
 }
 .beta .counter_div div span{
  font-size: 15px;
 }
}
@media (max-width:500px) {
 .beta{
  padding-top: 25px;
  height:fit-content;
 }
 .beta .counter_div .count1, .beta .counter_div .count2, .beta .counter_div .count3 {
  font-size: 70px;
 }
 .beta .counter_div {
  height: 150px;
 }
}
@media (max-width:350px) {
 .beta {
  padding-top: 50px;
  padding-bottom: 40px;
 }
 .beta .counter_div .count1, .beta .counter_div .count2, .beta .counter_div .count3 {
  font-size: 60px;
 }
}

@media (max-width:310px) {
 .beta #title {
  font-size: 43px;
 }
 .beta .counter_div .count1, .beta .counter_div .count2, .beta .counter_div .count3 {
  font-size: 50px;
 }
}