.new_features .new_features_row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new_features .new_features_row .new_features_row_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
}

.dropdown #dropdownMenuButton {
    background-color: #F5F5F5 !important;
    border-radius: 5px !important;
}

.dropdown-item {
    background: unset !important;
}

.dropdown-menu {
    top: -5px !important;
    min-width: 12rem !important;
    width: 200px !important;
    background-color: #F5F5F5 !important;
    border-radius: unset !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    font-size: 16px;
}

.dropdown-menu a {
    font-size: 15px !important;
    color: #383838 !important;
}

.dropdown-menu a:hover {
    background-color: #dcdcdc !important;
}

.dropdown .dropdown-toggle {
    width: 200px !important;
}

.dropdown-toggle::after {
    margin-left: 1em !important;
}

.content_list {
    padding: 10px;
    height: fit-content;
    width: 100%;
    background-color: #F5F5F5 !important;
    border: 5px solid white;
    border-radius: 10px !important;
}

.content_list_item {
    height: fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    background: unset !important;
}

#content_list_item_text {
    font-size: 18px;
    color: #363636;
}

#feature_dot {
    height: 1.3rem !important;
    margin-left: 10px;
    margin-right: 20px;
    fill: #3C34FF !important;
    background: unset !important;
}

#bug_dot {
    height: 1.3rem !important;
    margin-left: 10px;
    margin-right: 20px;
    fill: #FD852D !important;
    background: unset !important;
}


/*responsive*/
@media(max-width:1010px) {
    .new_features {
        max-width: 100% !important;
    }
}

@media(max-width:900px) {
    .new_features .new_features_row .new_features_row_header {
        width: 90% !important;
    }

    .sub_nav {
        width: 90% !important;
    }

    .feature_container {
        width: 90% !important;
    }
}

@media(max-width:709px) {
    .sub_nav {
        width: 100% !important;
    }

    .feature_container {
        width: 100% !important;
    }
}

@media(max-width:640px) {
    .content_list {
        padding-right: 50px !important;
        width: fit-content;
    }

    #content_list_item_text {
        font-size: 16px;
    }
}

@media(max-width:1300px) {
    .sub_nav {
        flex-direction: column;
        align-items: center;
    }

    .features_content {
        width: 100% !important;
        align-items: center;
    }

    .content_list {
        width: 100% !important;
    }
}

@media(max-width:445px) {
    .new_features .new_features_row .sub_nav {
        width: 100% !important;
    }
}