.install_navbar {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cancel_btn:hover{
   opacity:0.7;
}
.install_navbar_row {
    width: 100%;
    display: flex;
    justify-content: center;
}

.brand_cont {
    width: 100%;
    display: flex;
}

.install_navbar_img {
    margin-top: 5px;
    margin-right: 20px;
    height: 97px;
    width: 100px;
    display: grid;
    place-items: center;
    border-radius: 10px !important;
    border: 1px solid #e5e1e1;
}

.install_navbar_img img {
    height: 70px;
    width: 70px;
}

.install_navbar span {
    top: -10px;
    font-size: 14px;
    position: relative;
}
