.block .block_banner {
 display: flex;
 justify-content: center;
 overflow: hidden;
}

.block .block_banner img {
 height: 370px;
 width: 550px;
}

.tensorflow_cont {
 display: flex;
 justify-content: center;
}

.tensorflow_cont #tensorflow_img {
 height: 300px;
 width: 85%;
}

.tensorflow_cont_title {
 margin-top: 50px;
 min-height: 200px;
 min-width: 700px;
 display: flex;
 justify-content: space-between;
 align-items: center;
 position: absolute;
 background: unset !important;
}

.tensorflow_cont_title h3{
 background-color: unset !important;
 color: black !important;
}

@media(max-width:1400px) {
 .tensorflow_cont #tensorflow_img {
  height: 100%;
  width: 100%;
 }
}

@media(max-width:1000px) {
 .block .block_banner img {
  height: 100% !important;
  width: 100% !important;
 }

 .tensorflow_cont_title {
  margin-top: 0px;
  min-height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
 }
}

@media(max-width:778px) {
 .tensorflow_cont_title {
  min-height: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
 }
}

@media(max-width:700px) {
 .tensorflow_cont_title {
  min-height: 150px;
  min-width: 100% !important;
  position: absolute;
 }

 .block .block_banner {
  flex-direction: column;
 }
}

@media(max-width:450px) {
 .tensorflow_cont_title {
  min-height: 110px;
  min-width: 100% !important;
  backdrop-filter: blur(5px);
  position: absolute;
 }
}

@media(max-width:350px) {
 .tensorflow_cont_title {
  min-height: 90px;
  min-width: 100% !important;
  position: absolute;
 }

 .tensorflow_cont_title h3 {
  font-size: 18px;
 }
}

@media(max-width:300px) {
 .tensorflow_cont_title {
  min-height: 70px;
  min-width: 100% !important;
  position: absolute;
 }
}