.para1 {
 margin-top: 20px;
 background-color: rgb(239, 239, 239);
 line-height: 30px;
 border-radius: 5px;
}

.img_containers {
 display: flex;
 justify-content: space-between;
}

.img_containers img {
 height: 400px;
 width: 500px;
}

.guide_installation1 ol{
  padding: 20px;
  padding-top: 0;
  line-height: 30px;
}

@media (max-width:1400px) {
 .img_containers {
  flex-direction: column;
 }

 .img_containers img {
  height: 100%;
  width: 100%;
 }
}