.terms{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
}
.terms .content{
    border-top: 1px solid #bbbbbb;
}
.terms p{
    width:900px;
    text-align: justify;
}


/*responsive*/
@media(max-width:1000px){
    .terms p{
        width:100%;
    }

}

