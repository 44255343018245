.quick_guide {
    height: fit-content;
    width: 100%;
    display: flex;
    overflow-x: hidden;
}

/* body row */
.getStartedRow {
    height: 100vh;
    overflow: scroll;
}