/* main container */
.imgClassificationCont {
  height: 80vh;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* main container body */
.imgClassificationCont .classific_body {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* image box */
.imgClassificationCont .classific_body .img_box {
  margin-top: 50px;
  height: 300px;
  width: 300px;
  display: grid;
  place-items: center;
  background-color: #0B0B0B;
  border: 1px solid rgb(73, 73, 73) !important;
  border-radius: 10px;
  overflow: hidden;
}

.imgClassificationCont .classific_body .img_box .content .audio_name {
  padding-left: 10px;
  padding-right: 30px;
}

.imgClassificationCont .classific_body .img_box #photoIcon {
  height: 100px;
  width: 100px;
}

/* result box */
.imgClassificationCont .classific_body .output_console_card {
  padding-bottom: 20px;
  height: fit-content;
  width: 400px;
  background-color: #0B0B0B;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgb(73, 73, 73) !important;
}

.imgClassificationCont .classific_body .output_console_card h6 {
  padding: 10px;
  width: 100%;
  background-color: #7200E3 !important;
  border-radius: 0px !important;
  border: 0px solid black !important;
}


.imgClassificationCont .classific_body .output_console_card .result_box span {
  display: block;
}

.imgClassificationCont .classific_body .output_console_card .result_box span {
  margin-left: 10px;
  padding: 3px;
}

#camCont {
  margin-top: -49px;
}

/* footer */
.imgClassificationCont .classific_footer {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.imgClassificationCont .classific_footer .btn {
  margin-left: 20px;
  margin-right: 20px;
  color: white;
  background-color: #4A3BFF !important;
}

#footer_icon {
  fill: white !important;
  background: unset !important;
}

.imgClassificationCont #detect_btn {
  height: fit-content;
  border-radius: 5px !important;
  background-color: #4A3BFF;
}


/* responsive */
@media(max-width:1100px) {
  .imgClassificationCont {
    height: 60vh;
  }

  .imgClassificationCont .classific_body {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  /* connector */
  .imgClassificationCont .classific_body .connector {
    display: none;
  }

  .imgClassificationCont .classific_footer {
    margin-top: 40px;
  }

}

@media(max-width:500px) {
  .imgClassificationCont .classific_body .output_console_card {
    width: 100%;
  }

  .imgClassificationCont .classific_body .img_box {
    width: 100%;
  }

}