.premium {
  display: flex;
  justify-content: center;
}

.premium_row {
  padding: 50px;
  padding-top: 70px;
  padding-bottom: 70px;
  margin-left: -25px;
  height: fit-content;
  width: 960px;
  display: flex;
  justify-content: center;
  border-radius: 5px !important;
  background: url("/public/assets/camp.png");
  box-shadow: 0px 2px 5px 0px rgba(153, 153, 153, 0.75);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(153, 153, 153, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(153, 153, 153, 0.75);
}

.premium_row .body {
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  width: fit-content;
  text-align: center;
  backdrop-filter: blur(8px) !important;
  background: rgba(138, 71, 71, 0.5) !important;
  border-radius: 10px !important;
}

.premium_row .body .text-content {
  opacity: 1 !important;
  background: unset !important;
}
.premium_row .body .text-content h4 {
  background: unset !important;
}
.premium_row .body .text-content a {
  width: 50%;
  border-radius: 50px !important;
}

.premium_row .body .text-content span {
  width: 20%;
  background: unset !important;
}

@media (max-width: 1100px) {
  .premium {
    padding: 0;
    max-width: 100% !important;
  }

  .premium_row {
    padding: 30px;
    width: 610px;
    margin-left: 0px;
  }

  .premium_row .body {
    padding: 20px;
    backdrop-filter: blur(8px) !important;
    background-color: rgb(68, 68, 68, 0.4);
    font-size: 14px;
    flex-direction: column;
  }

  .premium_row .body .text-content span {
    color: rgb(255, 242, 242) !important;
  }
}

@media (max-width: 800px) {
  .premium_row {
    padding: 30px;
    width: 100%;
    margin-left: 0px;
  }
}

@media (max-width: 400px) {
  .premium_row .body .text-content a {
    width: 90%;
  }
}
