.para1 {
  margin-top: 20px;
  background-color: rgb(239, 239, 239);
  line-height: 30px;
  border-radius: 5px;
}
.img_containers {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img_containers .img {
  border-radius: 10px;
}

@media (max-width: 1400px) {
  .img_containers {
    flex-direction: column;
  }
}
