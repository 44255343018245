.gestureDetection {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gestureDetection .video_container {
  height: 300px;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: black !important;
  border: 1px solid rgb(92, 90, 90) !important;
}

#camCont {
  margin-top: 0px;
}

.css-i4bv87-MuiSvgIcon-root {
 background-color: unset !important;
}

  #canvas {
    position: absolute;
    background-color: unset !important;
  }

  .gestureDetection .video_container .video_footer {
    background-color: red !important;
  }

  .start_btn {
    margin-right: 20px;
    border-radius: 5px !important;
    background-color: #200EF7 !important;
  }

  .stop_btn {
    margin-left: 20px;
    border-radius: 5px !important;
    background-color: red !important;
  }

  /* responsive */
  @media(max-width:630px) {
    .gestureDetection .video_container {
      width: 100%;
    }
  }

  @media(max-width:330px) {
    .start_btn {
      margin-right: 5px;
    }

    .stop_btn {
      margin-left: 5px;
    }
  }

  @media(max-width:300px) {
    .start_btn {
      margin-right: 0px;
      margin-bottom: 15px;
    }

    .stop_btn {
      margin-left: 0px;
    }

    .gestureDetection .video_footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }